import React, { useMemo } from 'react'
import clsx from 'clsx'
import { Typography, Link, useTheme } from '@mui/material'
import { GuardedImageTile } from '../tiles/GuardedImageTile'
import styles from './TeaserCard.module.scss'
import { ITeaser } from '@obeta/models/lib/models/Teasers/Teasers'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { Browser } from '@capacitor/browser'
import { trackClick } from '@obeta/utils/lib/tracking'
import { isInternalLink } from '@obeta/utils/lib/isInternalLink'
import { buildSearchUrl } from '@obeta/utils/lib/search/buildSearchUrl'
import { withMd5Id } from '@obeta/utils/lib/genMd5Id'
import { createSuppliersObject } from '@obeta/utils/lib/search/buildSearchUrlWithSupplierFilter'
import { ArticleSearchParams } from '@obeta/models/lib/models/Search'
import { useImgProxyUrls } from '@obeta/data/lib/hooks/useImgProxyUrls'

interface ITeaserCardProps extends ITeaser {
  isSmall?: boolean
}

const prepareLink = (url: string) => {
  let link: string | null
  if (url && (url.startsWith('/') || !isInternalLink(url))) {
    link = url
  } else {
    link = url ? `/${url}` : null
  }
  return link
}

export const TeaserCard: React.FC<ITeaserCardProps> = (props) => {
  const { id, teaserText, imageUrl, imageAlt, newsType, isSmall, internalName } = props
  const { mobile } = useBreakpoints()
  const history = useHistory()

  const { url, target, actionContext, route } = useMemo(() => {
    if (!newsType) return { url: undefined }
    // ComponentNewsTypesArticle
    if ('sapId' in newsType) {
      const articleId = newsType.sapId

      return {
        url: `${ShopRoutes.ArticleDetailPage}/${articleId}`,
        actionContext: {
          context: {
            articleId: articleId,
            type: 'article-details',
          },
        },
      }
    }

    // ComponentNewsTypesEnhanced
    if ('content' in newsType) {
      return {
        url: `${ShopRoutes.TeaserDetailPage}/${id}`,
        actionContext: {
          context: {
            newsId: id,
            type: 'teaser-details',
          },
        },
      }
    }

    // ComponentNewsTypesSearch
    if ('algoliaQuery' in newsType) {
      const searchString = newsType.algoliaQuery
      const suppliers = newsType.supplierName
        ?.split(',')
        .map((s) => s.trim())
        .filter(Boolean)

      const url = buildSearchUrl({
        searchString: searchString,
        suppliers: suppliers,
      })

      const params: ArticleSearchParams = {
        searchString: searchString,
        suppliers: suppliers ? createSuppliersObject(suppliers) : undefined,
      }

      const route = {
        pathname: ShopRoutes.Search,
        state: { searchParams: withMd5Id({ id: '', ...params }) },
      }

      return {
        url: url,
        route: route,
        actionContext: {
          newsId: id,
          type: 'teaser-details',
        },
      }
    }

    // ComponentNewsTypesLink
    if ('url' in newsType) {
      const link = prepareLink(newsType.url)
      const target = newsType.openInNewWindow
        ? '_blank'
        : isInternalLink(newsType.url)
        ? undefined
        : '_self'

      return {
        url: link,
        target: target,
        actionContext: {
          context: {
            link,
          },
        },
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _: never = newsType
    return {}
  }, [id, newsType])

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    // track both: one action with a concrete name containing an id and one generic action containing specific infos in context
    trackClick(`teaser-card-${id}`, { internalName })
    if (actionContext) trackClick('teaser-id-GENERIC', actionContext)

    if (!url) return
    if (e.getModifierState('Control') || e.getModifierState('Meta')) return

    if (target === undefined) {
      if (route != null) {
        history.push(route.pathname, route.state)
      } else {
        history.push(url)
      }
    } else {
      if (isPlatform('web')) {
        window.open(url, target)
      } else {
        Browser.open({ url: url })
      }
    }
  }

  const teaserTextVariant = mobile ? 'bodyBold' : 'headline4Bold'

  const DESKTOP_MIN_VIEWPORT_WIDTH = useTheme().breakpoints.values.lg
  const { imgProxyUrlDesktop } = useImgProxyUrls({
    url: imageUrl,
    desktopWidth: DESKTOP_MIN_VIEWPORT_WIDTH,
  })

  return (
    <Link
      href={url || '#'}
      className={clsx(styles['teaser-wrapper'])}
      onClick={handleClick}
      underline="none"
    >
      <div className={clsx(styles['teaser-img-wrapper'])}>
        <GuardedImageTile
          imageUrl={imgProxyUrlDesktop}
          alt={imageAlt}
          imageFit="cover"
          backgroundColor="transparent"
        />
      </div>
      <div
        className={clsx(styles['teaser-content'], { [styles['teaser-content__small']]: isSmall })}
      >
        <Typography variant={teaserTextVariant} className={styles['teaser-title']}>
          {teaserText}
        </Typography>
      </div>
    </Link>
  )
}
