import { buildNextRouterState } from '../history/buildNextRouterState'
import { isParsedUrlQueryInput, isUrlObject } from '../url'
import { withMd5Id } from '../genMd5Id'
import { ShopRoutes } from '../variables'
import { ArticleSearchParams } from '@obeta/models/lib/models/Search'
import { createSuppliersObject } from './buildSearchUrlWithSupplierFilter'

type Options = {
  searchString?: string
  suppliers?: string[]
}

export function buildSearchUrl(options: Options): string | undefined {
  const params: ArticleSearchParams = {}

  if (options.searchString) {
    params.searchString = options.searchString
  }

  if (options.suppliers) {
    params.suppliers = createSuppliersObject(options.suppliers)
  }

  const nextRouterState = buildNextRouterState(ShopRoutes.Search, {
    searchParams: withMd5Id({ id: '', ...params }),
  })

  if (isUrlObject(nextRouterState) && isParsedUrlQueryInput(nextRouterState.query)) {
    return `${nextRouterState.pathname}?state=${nextRouterState.query.state}`
  }
}
